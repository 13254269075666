const TotallyRandom = require("totally-random");
const random = new TotallyRandom();

const adjectives = [
  "5-star",
  "absolute",
  "absurd",
  "amateur",
  "ancient",
  "apprehensive",
  "arrogant",
  "belligerent",
  "big",
  "bitter",
  "boring",
  "broken",
  "callous",
  "careless",
  "celibate",
  "chauvinistic",
  "clammy",
  "closed-minded",
  "clueluess",
  "clumsy",
  "colossal",
  "cookie cutter",
  "cowardly",
  "creepy",
  "crusty",
  "cumbersome",
  "cursed",
  "daft",
  "deflated",
  "dehydrated",
  "dense",
  "dim",
  "dishonest",
  "dismal",
  "embarrassingly large",
  "empty",
  "expired",
  "fascist",
  "feeble",
  "finicky",
  "foolish",
  "generic brand",
  "grand",
  "great",
  "Great Value™",
  "grumpy",
  "gullible",
  "hairy",
  "indiscreet",
  "inferior",
  "insecure",
  "insufferable",
  "intolerable",
  "irresponsible",
  "jealous",
  "lil'",
  "limp",
  "little",
  "lowercase",
  "morbid",
  "narrow-minded",
  "obtuse",
  "old",
  "outdated",
  "outspoken",
  "petulant",
  "pimply",
  "pompous",
  "possessive",
  "primitive",
  "pudgy",
  "repulsive",
  "resentful",
  "rotten",
  "rotund",
  "shriveled",
  "silly",
  "simple",
  "single-celled",
  "slow",
  "stale",
  "supple",
  "sweaty",
  "tactless",
  "thoughtless",
  "timid",
  "tone-deaf",
  "top-notch",
  "top-shelf",
  "two-timing",
  "uncanny",
  "uncultured",
  "unfathomable",
  "unreliable",
  "unsightly",
  "untrustworthy",
  "unwashed",
  "useless",
  "vague",
  "vain",
  "vapid",
];

const nouns = [
  "ape",
  "airhead",
  "baboon",
  "balloon",
  "bastard",
  "bimbo",
  "bonehead",
  "boob",
  "boor",
  "bubble",
  "buffoon",
  "bum",
  "bumpkin",
  "butt nugget",
  "butterball",
  "caveman",
  "chonker",
  "chump",
  "chungus",
  "clown",
  "commoner",
  "communist",
  "country bumpkin",
  "coward",
  "creep",
  "cretin",
  "cuck",
  "cuckold",
  "degenerate",
  "dimwit",
  "ding dong",
  "dingleberry",
  "dingus",
  "doorknob",
  "donut",
  "dope",
  "dunce",
  "dweeb",
  "fart",
  "filth",
  "flat-earther",
  "fool",
  "garbage",
  "geezer",
  "gnat",
  "goob",
  "goober",
  "hag",
  "hick",
  "hillybilly",
  "hoodlum",
  "hooligan",
  "homewrecker",
  "idiot",
  "imbecile",
  "inbred",
  "incel",
  "jabroni",
  "knob",
  "lowlife",
  "man boob",
  "manchild",
  "mcNobody",
  "moron",
  "neanderthal",
  "neckbeard",
  "nincompoop",
  "nitwit",
  "noodle",
  "numnut",
  "nut",
  "nutjob",
  "oaf",
  "ogre",
  "peasant",
  "pest",
  "pleb",
  "popsicle",
  "porker",
  "potato",
  "reject",
  "riffraff",
  "schmuck",
  "simp",
  "simpleton",
  "single-meat sandwich",
  "sissy",
  "square",
  "swine",
  "trash",
  "troglodyte",
  "turd",
  "twat",
  "virgin",
  "waffle",
  "wanker",
  "weeb",
  "wimp",
  "wonton",
  "wretch",
];

const sentences = [
  "You're not the brightest crayon in the box",
  "The lights are on but nobody is home",
  "You're as sharp as a marble",
  "Your elevator doesn’t go all the way to the top floor",
  "You fell off of the stupid tree and hit every branch on the way down",
  "You have an IQ of room temperature",
  "All foam, no beer",
  "You're running on 3 cyclinders",
  "All volume no content",
  "Soft and dense like lead",
  "You're one french fry short of a happy meal",
  "You're as sharp as a brick",
  "You couldn't pour piss out of a boot with instructions written on the heel",
  "You're only rowing with one oar",
  "You're not playing with a full deck",
  "You're ten cents short of a dime",
  "The wheel is spinning but the hamster is dead",
  "An intellect rivaled only by garden tools",
  "Ain't no grain in your silo",
  "If you had a brain you'd be dangerous",
  "You're not firing on all 6 cyclinders",
  "The porch light ain't on",
  "You have bad luck when you're thinking",
  "Bless your heart",
];

const interjections = [
  "Aw",
  "Bravo",
  "Congrats",
  "Ew",
  "Oh",
  "Okay",
  "Oy",
  "Pff",
  "Shucks",
  "Well",
  "Wow",
  "Yikes",
  "Yeah",
];

export const slur = () => `${random.array(adjectives)} ${random.array(nouns)}`;
export const insultingSentence = () => random.array(sentences);
export const interjection = () => random.array(interjections);
